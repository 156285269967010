import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2a552203"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonLink: require('/home/userapp/components/Molecule/Button/ButtonLink.vue').default,IconLinkedin: require('/home/userapp/components/Atom/Icon/IconLinkedin.vue').default,ButtonIcon: require('/home/userapp/components/Molecule/Button/ButtonIcon.vue').default,IconInstagram: require('/home/userapp/components/Atom/Icon/IconInstagram.vue').default,IconYoutube: require('/home/userapp/components/Atom/Icon/IconYoutube.vue').default,IconFacebook: require('/home/userapp/components/Atom/Icon/IconFacebook.vue').default,IconBluesky: require('/home/userapp/components/Atom/Icon/IconBluesky.vue').default,Link: require('/home/userapp/components/Atom/Link.vue').default,Footer: require('/home/userapp/components/Organism/Footer.vue').default})
